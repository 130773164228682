import React, { useState } from 'react';
import logo from '../images/event_4_logo.png';
import { Link } from 'react-router-dom';

function Header() {
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <a href="#content" className="skip-link screen-reader-text">Hyppää sisältöön</a>
            <header id="top-header" className="site-header">
                <div className="site-identity">
                    <Link to="/" className="site-title" style={{ background: "white" }}>
                        <img src="assetsguide/images/logo.jpg" width="220" height="100" alt="Finlandluckyboom" />
                    </Link>
                    <p className="site-description screen-reader-text">Moderni HTML-sivumalli</p>
                </div>
                <div className="site-navigation">
                    <div className="site-general-information">
                        <div className="site-contact-details">
                            <a href="#"><em className="mdi mdi-email-outline"></em> <span className="__cf_email__">contact@Finlandluckyboom.com</span></a>
                            <a href="#"><span className="mdi mdi-map-marker-outline"></span> 642 Wallace Street, Nanaimo, Suomi</a>
                            <a href="#"><span className="mdi mdi-phone-outline"></span> +358 40 3424174</a>
                        </div>
                        <a href="#hotel1" className="button button-secondary">Varaa nyt</a>
                    </div>
                    <nav className="main-navigation">
                        <ul id="menu-main" className="menu">
                            <li className="current-menu-item"><Link to="/">Etusivu</Link></li>
                            <li className="menu-item-has-children">
                                <a href="#">Kasinon hotellit<em className="mdi mdi-chevron-down"></em></a>
                                <ul className="sub-menu">
                                    <li><a href="#hotel1">Chelsea Kasino Hotelli Toronto</a></li>
                                    <li><a href="#hotel2">Vittoria Kasino Hotelli & Suites</a></li>
                                    <li><a href="#hotel3">Great Canadian Kasino Hotelli</a></li>
                                    <li><a href="#hotel4">Pickering Kasino Resort Hotelli</a></li>
                                </ul>
                            </li>
                            <li><a href="#contact">Ota yhteyttä</a></li>
                        </ul>
                    </nav>
                    <a href="#menu-main" id="menu-toggle" title="Näytä valikko">
                        <em className="mdi mdi-menu"></em><em className="mdi mdi-close"></em>
                        <span className="screen-reader-text">Valikko</span>
                    </a>
                </div>
            </header>
        </>
    );
}

export default Header;
