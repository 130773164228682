import React from 'react';
import video1 from '../../src/assetsguide/sample-video1.mp4';
import backgroundImg from '../images/imagecopy.png';

function Main() {

    return (
        <body className="has-featured-image">
            <header id="featured" className="site-featured">
                <div id="featured-media" className="featured-media" style={{ background: `url(${backgroundImg})` }}>

                    <video src={video1} poster="tmp/sample-video1.html" autoplay loop muted></video>
                    <video loop autoPlay muted>
                        <source src={video1} type="video/mp4" />
                    </video>
                    <div className="featured-text">
                        <div className="entry-content">
                            <span className="tagline">Tervetuloa rakkaat vieraamme</span>
                            <h1 className="entry-title display" style={{ fontSize: "3.768em !important" }}>
                                Useilla hotellikohteilla ympäri Suomea olemme paras oppaasi pelata ja rentoutua mukavuudessa.
                            </h1>
                        </div>
                    </div>
                </div>
            </header>

            <div id="content" className="site-content">
                <main id="main" className="site-main">
                    <article className="entry entry-page">
                        <div className="entry-content">
                            <div className="block-media-text alignfull no-margin-bottom" id="hotel1">
                                <div className="text-part">
                                    <h3>Chelsea Kasino Hotelli Toronto</h3>
                                    <p>
                                        Chelsea Hotel on kiehtova kasinohotelli, joka yhdistää ylelliset majoitukset jännittäviin pelimahdollisuuksiin.
                                        Se lupaa unohtumattoman kaupunkiloman täydellisellä ylellisyyden ja jännityksen yhdistelmällä.
                                    </p>
                                    <ul className="features-list" style={{ width: "inherit" }}>
                                        <li>
                                            <em className="mdi mdi-gamepad-circle-right"></em> <span className="h6">Elektroniset pöytäpelit</span>
                                        </li>
                                        <li>
                                            <em className="mdi mdi-slot-machine-outline"></em> <span className="h6">Suurten panosten kolikkopelit ja pöydät</span>
                                        </li>
                                        <li>
                                            <em className="mdi mdi-table-chair"></em> <span className="h6">Hienostunut ravintola ja wellness</span>
                                        </li>
                                    </ul>
                                    <p>
                                        <a href="https://www.booking.com/hotel/ca/eaton-chelsea.en-gb.html" className="read-more">Varaa nyt <em className="mdi mdi-trending-neutral"></em></a>
                                    </p>
                                </div>
                                <div className="media-part"><img src="assetsguide/images/chelsea.jpg" alt="" /></div>
                            </div>
                            <div className="block-media-text alignfull no-margin-top no-margin-bottom alt-background" id="hotel2">
                                <div className="media-part"><img src="assetsguide/images/Vittoria.jpg" alt="" /></div>
                                <div className="text-part">
                                    <h3>Vittoria Kasino Hotelli & Suites</h3>
                                    <p>
                                        Vittoria Hotel & Suites Niagarassa, Suomi, tarjoaa ylellisen majoituksen ja laajan valikoiman pelimahdollisuuksia, 
                                        mikä lisää vierailuun jännitystä.
                                    </p>
                                    <ul className="features-list" style={{ width: "inherit" }}>
                                        <li><em className="mdi mdi-gamepad-circle-right"></em> <span className="h6">Pöytäpelit</span></li>
                                        <li><em className="mdi mdi-poker-chip"></em> <span className="h6">Pokerihuone</span></li>
                                        <li><em className="mdi mdi-slot-machine-outline"></em> <span className="h6">Suurten panosten kolikkopelit ja pöydät</span></li>
                                    </ul>
                                    <p>
                                        <a href="https://www.booking.com/hotel/ca/imperial-suites.en-gb.html" className="read-more">Varaa nyt <em className="mdi mdi-trending-neutral"></em></a>
                                    </p>
                                </div>
                            </div>
                            <div className="block-media-text alignfull no-margin-top no-margin-bottom" id="hotel3">
                                <div className="text-part">
                                    <h3>Great Canadian Casino Hotel</h3>
                                    <p>
                                        Suurimman pelivalikoiman tarjoava hotelli tarjoaa myös maksutonta katettua pysäköintiä ja ympäri vuorokauden valvontaa. 
                                        Nauti monipuolisista ruokailuvaihtoehdoista, jotka sopivat eri makuihin. Varattavissa on tyylikkäitä ja mukavia huoneita 
                                        lähellä Toronton Pearsonin lentokenttää.
                                    </p>
                                    <ul className="features-list" style={{ width: "inherit" }}>
                                        <li><em className="mdi mdi-gamepad-circle-right"></em> <span className="h6">Elektroniset pöytäpelit</span></li>
                                        <li><em className="mdi mdi-slot-machine-outline"></em> <span className="h6">Suurten panosten kolikkopelit ja pöydät</span></li>
                                        <li><em className="mdi mdi-poker-chip"></em> <span className="h6">Pokerihuone</span></li>
                                    </ul>
                                    <p>
                                        <a href="https://www.booking.com/hotel/ca/great-canadian-casino-resort-toronto.en-gb.html" className="read-more">Varaa nyt <em className="mdi mdi-trending-neutral"></em></a>
                                    </p>
                                </div>
                                <div className="media-part"><img src="assetsguide/images/Great.jpg" alt="" /></div>
                            </div>
                            <div className="block-media-text alignfull no-margin-top alt-background" id="hotel4">
                                <div className="media-part"><img src="assetsguide/images/Pickering.jpg" alt="" /></div>
                                <div className="text-part">
                                    <h3>Pickering Casino Resort Hotel</h3>
                                    <p>
                                        Yli 2,200 interaktiivista kolikkopeliä ja 90 live-pöytäpelin lisäksi hotellissa on laaja valikoima ruokailuvaihtoehtoja 
                                        sekä 275 modernisti varusteltua huonetta ja sviittiä.
                                    </p>
                                    <ul className="features-list" style={{ width: "inherit" }}>
                                        <li><em className="mdi mdi-slot-machine"></em> <span className="h6">Suurten panosten kolikkopelit ja pöydät</span></li>
                                        <li><em className="mdi mdi-slot-machine-outline"></em> <span className="h6">Kolikkopelit</span></li>
                                        <li><em className="mdi mdi-gamepad-circle-right"></em> <span className="h6">Pöytäpelit</span></li>
                                    </ul>
                                    <p>
                                        <a href="https://www.booking.com/hotel/ca/pickering-casino-resort.en-gb.html" className="read-more">Varaa nyt <em className="mdi mdi-trending-neutral"></em></a>
                                    </p>
                                </div>
                            </div>
                            <div className="spacing1"></div>
                            <h2 className="text-center">Tutustu Suomen parhaisiin kasinolomakohteisiin</h2>
                            <hr />
                            <p className="text-center">
                                Etsitkö lomaa, joka yhdistää ylellisen asumisen ja kasinoviihteen?
                                Suomi tarjoaa monia huipputason kasinohotelleja unohtumattomilla elämyksillä. 
                                Olitpa kiinnostunut yksityisistä kattoterasseista tai ylellisistä sviiteistä upeilla näköaloilla, 
                                nämä valitut kohteet tarjoavat monipuolisia palveluja, mukaan lukien pokerihuoneet, kolikkopelit ja muuta.
                            </p>
                            <div className="block-grid alignwide text-center">
                                <div className="grid-item">
                                    <div className="grid-item-icon">
                                        <em className="mdi mdi-cards-club"></em>
                                    </div>
                                    <h5 className="grid-item-title">Pokerihuone</h5>
                                    <p>
                                        Kasinohotelliemme pokerihuoneet tarjoavat ainutlaatuisen ja hienostuneen pelielämyksen,
                                        joka sopii sekä kokeneille pelaajille että uusille tulokkaille.
                                    </p>
                                </div>
                                <div className="grid-item">
                                    <div className="grid-item-icon">
                                        <em className="mdi mdi-slot-machine-outline"></em>
                                    </div>
                                    <h5 className="grid-item-title">Kolikkopelit</h5>
                                    <p>
                                        Kasinohotelliemme kolikkopelit tarjoavat laajan valikoiman jännittäviä pelejä klassisista 
                                        suosikeista uusimpiin innovaatioihin. Graafisesti houkuttelevat pelit ja viihdyttävä pelaaminen 
                                        tarjoavat loputtomia mahdollisuuksia voittaa.
                                    </p>
                                </div>
                                <div className="grid-item">
                                    <div className="grid-item-icon">
                                        <em className="mdi mdi-gamepad-circle-down"></em>
                                    </div>
                                    <h5 className="grid-item-title">Elektroniset pöytäpelit</h5>
                                    <p>
                                        Kasinohotellimme tarjoavat modernin version perinteisestä pelaamisesta yhdistämällä live-pelien jännityksen 
                                        digitaalisen pelaamisen mukavuuteen.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </article>
                </main>
            </div>
        </body>
    );
}

export default Main;
