import React from 'react'

function Terms() {
  return (
    <div id="content" className="site-content">
      <main id="main" className="site-main">
        <div className="entry entry-page">
          <div className="blog-listing">
            <article className="entry entry-post has-post-thumbnail">
              <div className="entry-header">
                <h2><a href="#">Käyttäytymissäännöt</a></h2>
              </div>
              <div className="entry-header">
                <h3><a href="#">Kasinoon pääsy</a></h3>
              </div>

              <div className="entry-content">
                <div className="entry-excerpt">
                  <p>Haluamme toivottaa sinut tervetulleeksi! Kiitos vierailustasi!</p>
                  <p>
                    Vieraidemme ja henkilökuntamme turvallisuuden vuoksi pääsy kasinolle evätään tai
                    sinua pyydetään poistumaan tiloista, jos:
                  </p>
                  <ul>
                    <li>olet alle 19-vuotias*;</li>
                    <li>
                      et esitä hyväksyttävää valtion myöntämää kuvallista henkilötodistusta sisäänpääsyä
                      varten*;
                    </li>
                    <li>olet alkoholin, huumeiden tai laittomien aineiden vaikutuksen alaisena;</li>
                    <li>
                      hallussasi on ulkopuolinen alkoholijuoma, laiton aine tai ase;
                    </li>
                    <li>
                      käytät vaatteita tai asusteita, joissa on jengiin liittyviä tunnuksia/emblemejä,
                      säädyttömiä grafiikoita tai sopimatonta kielenkäyttöä;
                    </li>
                    <li>vahingoitat tai tuhoat yhtiön omaisuutta;</li>
                    <li>
                      kieltäydyt osallistumasta GCE:n tai terveysviranomaisten ajoittain määräämiin
                      pakollisiin terveys- ja turvallisuustoimenpiteisiin; tai
                    </li>
                    <li>
                      muista kohtuullisista syistä, jotka johto tai turvallisuushenkilöstö katsoo
                      aiheellisiksi.
                    </li>
                  </ul>
                </div>
              </div>
            </article>

            <article className="entry entry-post has-post-thumbnail">
              <div className="entry-header">
                <h3><a href="#">Vieraiden kokemus</a></h3>
              </div>

              <div className="entry-content">
                <div className="entry-excerpt">
                  <p>
                    Olemme sitoutuneet luomaan turvallisen, vieraanvaraisen ja unohtumattoman
                    kokemuksen kaikille vieraillemme.
                  </p>
                  <p>
                    Pyydämme, että kunnioitat muiden oikeutta nauttia viihdekokemuksestaan ja
                    käyttäydyt asianmukaisesti kaikkina aikoina.
                  </p>
                  <ul>
                    <li>Kiroileminen, uhkaava tai loukkaava kielenkäyttö tai eleet;</li>
                    <li>Tappelut;</li>
                    <li>Uhkaava tai loukkaava käytös muita kohtaan;</li>
                    <li>Kannabiksen käyttö, polttaminen tai höyrystäminen GCE:n alueella; ja</li>
                    <li>Polttaminen tai höyrystäminen muualla kuin siihen tarkoitetuilla alueilla.</li>
                  </ul>
                </div>
              </div>
            </article>

          </div>
        </div>
      </main>
    </div>
  );
}

export default Terms;
