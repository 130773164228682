import React from 'react';

function PrivacyPolicy() {
  return (
    <div id="content" className="site-content">
      <main id="main" className="site-main">
        <div className="entry entry-page">
          <div className="blog-listing">
            <article className="entry entry-post has-post-thumbnail">
              <div className="entry-header">
                <h2><a href="#">Tietosuojaseloste</a></h2>
              </div>

              <div className="entry-content">
                <div className="entry-excerpt">
                  <p>
                    canadianguidetowin Gaming Corporation ja sen tytäryhtiöt (yhdessä “GCGC”) ovat
                    sitoutuneet varmistamaan, että sinulla on paras mahdollinen kokemus tiloissamme. 
                    Sinulla on oikeus tietää, kuinka henkilötietojasi kerätään ja mitä me teemme niillä, 
                    jotta voit tehdä tietoisia valintoja. Olemme luoneet tämän tietosuojaselosteen 
                    selittääksemme yksinkertaisesti, mitä teemme ja mitä emme tee henkilötietojesi kanssa.
                  </p>
                </div>
              </div>
            </article>

            <article className="entry entry-post has-post-thumbnail">
              <div className="entry-header">
                <h2><a href="#">Henkilötietojen Kerääminen ja Käyttö</a></h2>
              </div>

              <div className="entry-content">
                <div className="entry-excerpt">
                  <p>
                    GCGC voi kerätä joitain henkilötietoja sinusta OLGC-lain mukaisella laillisella 
                    valtuudella.
                  </p>
                  <p><strong>Henkilötiedot voidaan kerätä seuraavista lähteistä:</strong></p>
                  <ul>
                    <li>Kun käytät verkkosivustoamme (https://canadianguidetowin.com/);</li>
                    <li>Kun otat meihin yhteyttä sähköpostitse, tekstiviestillä, puhelimitse tai muilla tavoilla;</li>
                    <li>Kun vuorovaikutat mainontamme ja sovellustemme kanssa kolmansien osapuolten verkkosivustoilla.</li>
                  </ul>
                  <p><strong>GCGC kerää seuraavia henkilötietoja seuraaviin tarkoituksiin:</strong></p>
                  <ul>
                    <li>
                      <strong>Turvallisuus:</strong> Nimi, osoite, puhelinnumero, syntymäaika, sukupuoli, ajokortin numero, 
                      valtion myöntämä henkilötodistus, passinumero, ajoneuvon rekisterinumero, ajoneuvon kuvaus, videokuvat ja valokuvat – 
                      Näitä tietoja kerätään turvallisuustapauksissa, laittoman tai epäilyttävän toiminnan valvonnassa ja lakien noudattamiseksi.
                    </li>
                    <li>
                      <strong>Kilpailut ja Kampanjat:</strong> Nimi, osoite tai sähköpostiosoite, puhelinnumero – 
                      Nämä tiedot kerätään, kun rekisteröidyt kilpailuihin, kampanjoihin, uutiskirjeisiin tai palveluihin.
                    </li>
                    <li>
                      <strong>Markkinointi ja Mainonta:</strong> Nimi, osoite, sähköposti, syntymäaika, sukupuoli, 
                      tapahtuma- ja pelihistoria – Saatamme käyttää näitä tietoja markkinointiin ja mainontaan.
                    </li>
                    <li>
                      <strong>Pelaajatilit:</strong> Nimi, osoite, sähköposti, puhelinnumero, syntymäaika, 
                      valtion myöntämä henkilötodistus – Näitä tietoja kerätään, kun rekisteröidyt pelaajatilillemme.
                    </li>
                    <li>
                      <strong>Asiakaspalautteet ja Tutkimus:</strong> Tiedot, jotka annat meille palautteesi, kysymystesi tai valituksiesi yhteydessä.
                    </li>
                  </ul>
                </div>
              </div>
            </article>

            <article className="entry entry-post has-post-thumbnail">
              <div className="entry-header">
                <h2><a href="#">Verkkosivustoltamme Kerätyt Tiedot</a></h2>
              </div>

              <div className="entry-content">
                <div className="entry-excerpt">
                  <p>
                    GCGC kerää myös tiettyjä tietoja verkkosivustoltamme. Esimerkiksi, kun vierailet sivuillamme, 
                    keräämme vierailusi päivämäärän ja kellonajan sekä selaintyyppisi, IP-osoitteesi ja muita vastaavia tietoja. 
                    Käytämme näitä tietoja sisäisiin turvallisuusraportteihin ja järjestelmän hallintaan.
                  </p>
                  <p>
                    Sivustomme saattavat myös käyttää “evästeitä”. Eväste on pieni tiedosto, joka tallennetaan tietokoneesi kiintolevylle.
                    Voit estää evästeet muuttamalla selaimesi asetuksia, mutta se voi vaikuttaa joidenkin sivuston osien toimivuuteen.
                  </p>
                </div>
              </div>
            </article>

            <article className="entry entry-post has-post-thumbnail">
              <div className="entry-header">
                <h2><a href="#">Henkilötietojen Luovutus</a></h2>
              </div>

              <div className="entry-content">
                <div className="entry-excerpt">
                  <p>
                    Yleensä GCGC luovuttaa henkilötietojasi vain suostumuksellasi. Emme myy henkilötietojasi kolmansille osapuolille. 
                    Voimme kuitenkin luovuttaa tietojasi ilman suostumustasi tietyissä lain edellyttämissä tilanteissa, 
                    kuten yhteistyössä viranomaisten kanssa tai lainvalvontatarkoituksiin.
                  </p>
                  <ul>
                    <li>
                      Tytäryhtiöillemme ja palveluntarjoajillemme – Saatamme jakaa tietoja palveluntarjoajille, jotka suorittavat palveluita puolestamme.
                    </li>
                    <li>
                      Lainvalvontaviranomaisille laittoman toiminnan tutkimiseksi.
                    </li>
                    <li>
                      Kansanterveysviranomaisille pandemioiden, kuten COVID-19:n leviämisen estämiseksi.
                    </li>
                  </ul>
                </div>
              </div>
            </article>

            <article className="entry entry-post has-post-thumbnail">
              <div className="entry-header">
                <h2><a href="#">Henkilötietojen Säilyttäminen</a></h2>
              </div>

              <div className="entry-content">
                <div className="entry-excerpt">
                  <p>
                    Säilytämme henkilötietoja niin kauan kuin se on tarpeellista tässä tietosuojaselosteessa määriteltyjen tarkoitusten toteuttamiseksi.
                    Tietojen säilytysajat voivat vaihdella lainsäädännön tai sopimusten perusteella.
                  </p>
                </div>
              </div>
            </article>

            <article className="entry entry-post has-post-thumbnail">
              <div className="entry-header">
                <h2><a href="#">Pääsy Tietoihisi</a></h2>
              </div>

              <div className="entry-content">
                <div className="entry-excerpt">
                  <p>
                    Sovellettavan tietosuojalainsäädännön mukaisesti voit pyytää pääsyä omiin henkilötietoihisi sekä 
                    tietoja siitä, miten tietosi on kerätty, käytetty ja luovutettu. Voit lähettää virallisen pyynnön 
                    tietojen tarkasteluun tietosuojavastaavallemme.
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </main>
    </div>
  );
}

export default PrivacyPolicy;
