import React from 'react';
import { Link } from 'react-router-dom';
import video1 from '../../src/assetsguide/sample-video1.mp4';

function Footer() {
  return (
    <>
      <footer className="site-reviews">
        <div className="entry-content">
          <div className="reviews-wrap">
            <h3 className="reviews-title"><span className="sep-underline">Vieraskirja</span></h3>
            <p className="tagline">Katso, mitä muut ajattelivat yöpymisestä hotellissamme.</p>
            <div className="reviews-content">
              <div id="TA_selfserveprop572" className="TA_selfserveprop">
                <a className="widSSPClickWrap" target="_blank"
                  href="https://www.tripadvisor.com/Hotel_Review-g808078-d953293-Reviews-DAS_EDELWEISS_Salzburg_Mountain_Resort-Grossarl_Austrian_Alps.html">
                  <img src="https://www.tripadvisor.com/img/cdsi/img2/branding/150_logo-11900-2.png"
                    alt="TripAdvisor" />
                </a>
              </div>
              <script data-cfasync="false"
                src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script>
              <script async
                src="https://www.jscache.com/wejs?wtype=selfserveprop&amp;uniq=572&amp;locationId=183778&amp;lang=en_US&amp;rating=true&amp;nreviews=4&amp;writereviewlink=false&amp;popIdx=true&amp;iswide=true&amp;border=false&amp;display_version=2"
                data-loadtrk onload="this.loadtrk=true"></script>
            </div>
          </div>
        </div>
        <video loop autoplay muted>
          <source src={video1} type="video/mp4" />
        </video>
        <video loop autoPlay muted>
          <source src={video1} type="video/mp4" />
        </video>
      </footer>

      <footer className="site-footer">
        <a className="to-the-top" href="#top-header" title="Ylös"><em className="mdi mdi-arrow-up"></em></a>
        <div className="widget-area columns-3">
          <div className="widget widget_text" id="contact">
            <h5 className="widget-title"><span className="sep-underline">Ota yhteyttä</span></h5>
            <div className="textwidget custom-html-widget">
              <p>        +358 40 3424174<br /> <a href='#'>contact@Finlandluckyboom.com</a><br />
                <strong>Sisäänkirjautuminen:</strong> 11:00 - 22:00<br />
                <strong>Uloskirjautuminen:</strong> Klo 10:00 asti
              </p>
            </div>
          </div>
          <div className="widget widget_text">
            <h5 className="widget-title"><span className="sep-underline">Sijainti</span></h5>
            <div className="textwidget custom-html-widget">
              <p>
                642 Wallace Street<br /> Nanaimo, Suomi<br /> <a href="https://maps.app.goo.gl/nGeWyZMTZWQV7BXt8"
                  className="read-more" target="_blank">
                  Hae reittiohjeet <em className="mdi mdi-trending-neutral"></em>
                </a>
                <br /> <a href="#" className="read-more" target="_blank"></a>
              </p>
            </div>
          </div>
          <div className="widget widget_nav_menu">
            <h5 className="widget-title"><span className="sep-underline">Hyödyllisiä linkkejä</span></h5>
            <nav className="footer-useful-links">
              <ul id="useful-links-menu" className="menu">
                <li><Link to="/terms">Ehdot</Link></li>
                <li><Link to="/privacypolicy">Tietosuojakäytäntö</Link></li>
              </ul>
            </nav>
          </div>
        </div>
        <aside className="site-footer-bottom">
          <div className="site-footer-credits">
            &copy; 2024 <a href="#">Finlandluckyboom.com </a>Kaikki oikeudet pidätetään.
          </div>
          <div className="site-social-links">
            <nav className="social-navigation">
              <ul id="social-menu" className="menu">
                <li>
                  <a href="https://facebook.com/"><span className="screen-reader-text">Facebook-profiili</span></a>
                </li>
                <li>
                  <a href="https://twitter.com/"><span className="screen-reader-text">Twitter-profiili</span></a>
                </li>
                <li>
                  <a href="https://instagram.com/">
                    <span className="screen-reader-text">
                      Instagram-profiili
                    </span>
                  </a>
                </li>
                <li><a href="https://youtube.com/"><span className="screen-reader-text">YouTube-sivu</span></a></li>
                <li>
                  <a href="https://pinterest.com/">
                    <span className="screen-reader-text">
                      Pinterest-profiili
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </aside>
      </footer>
    </>
  );
}

export default Footer;
